import * as Sqrl from 'squirrelly';

import { SessionService } from '../../services';
import state from '../../state';
import { GadgetGuide, GadgetGuideItemList, HTMLTemplate } from '../types';
import { buildGadgetRequestPayload } from '../utils';

import template from './gadget-guide-response.html';
import './gadget-guide-response.scss';

export class XGadgetGuideResponse extends HTMLElement {
  constructor() {
    super();

    // create a shadow root
    this.attachShadow({ mode: 'open' });
  }

  // custom element lifecycle callback
  public connectedCallback() {
    XGadgetGuideResponse.init(document, this.shadowRoot);
  }

  // reset the gadget guide render count and re-render the gadget guide
  public resetAndRetryGadgetGuide() {
    state.gadgetGuideRenderCount = 0;

    this.connectedCallback();
  }

  // build a list of items
  public static buildItemList(
    items: GadgetGuideItemList[] = null,
    key: keyof GadgetGuideItemList = null,
    element = 'span'
  ) {
    return key
      ? items
          ?.map(
            (item: GadgetGuideItemList) =>
              `<${element}>${item[key]}</${element}>`
          )
          .join('')
      : '';
  }

  // get the gadget guide instructions for the current user agent
  public static async getGadgetGuide(requestPayload: Record<string, unknown>) {
    let title: string;
    let introduction: string;
    let steps: GadgetGuideItemList[];
    let additionalInfo: GadgetGuideItemList[];
    let hasError: boolean;

    try {
      const gadgetGuideResponse = await SessionService.postGadgetGuide(
        requestPayload
      );
      const gadgetGuideData = gadgetGuideResponse.data;

      title = gadgetGuideData.title;
      introduction = gadgetGuideData.introduction;
      steps = gadgetGuideData.steps;
      additionalInfo = gadgetGuideData.additionalInfo;
      hasError = false;
    } catch (error) {
      title = '';
      introduction = '';
      steps = [];
      additionalInfo = [];
      hasError = true;
    }

    return {
      title,
      introduction,
      steps,
      additionalInfo,
      hasError,
    };
  }

  // initialize the gadget guide
  public static init(doc: Document, shadow: ShadowRoot) {
    const loader = doc.createElement('div');

    doc
      .querySelector(`div[${GadgetGuide.DefaultContentAttribute}]`)
      ?.setAttribute(
        `${GadgetGuide.DefaultContentAttribute}--hidden`,
        `${GadgetGuide.DefaultContentAttribute}--hidden`
      );

    XGadgetGuideResponse.renderSkeletonLoader(
      loader,
      shadow,
      '<div class="skel-container"><div class="skel skel--has-pulse skel--text"></div></div>'
    );

    XGadgetGuideResponse.renderGadgetGuide(document, shadow, loader);
  }

  // render the gadget guide
  public static async renderGadgetGuide(
    doc: Document,
    shadow: ShadowRoot,
    loader: HTMLDivElement
  ) {
    // if we should not render gadget guide, show default content
    if (!this.shouldRenderGadgetGuide()) {
      this.showDefaultContent(doc, loader);

      // do not continue
      return;
    }

    // get gadget guide payload
    const {
      browserType,
      browserVersion,
      deviceModel,
      deviceName,
      osType,
      osVersion,
      gadgetType,
    } = buildGadgetRequestPayload();

    // get gadget guide instructions
    const { title, introduction, steps, additionalInfo, hasError } =
      await this.getGadgetGuide({
        browserType,
        browserVersion,
        deviceModel,
        deviceName,
        osType,
        osVersion,
        gadgetType,
      });

    // show default content on caught error
    if (hasError) {
      this.showDefaultContent(document, loader);
    }

    // build the gadget guide content
    const titleResult = title ? `<h1>${title}</h1>` : '';
    const introductionResult = introduction ? `<p>${introduction}</p>` : '';
    const instructionListItemResult = XGadgetGuideResponse.buildItemList(
      steps,
      'instruction',
      'li'
    );

    const instructionListResult = instructionListItemResult
      ? `<ol>${instructionListItemResult}</ol>`
      : '';

    const additionalListItemResult = XGadgetGuideResponse.buildItemList(
      additionalInfo,
      'text',
      'p'
    );

    const additionalInfoListResult = additionalListItemResult
      ? additionalListItemResult
      : '';

    // remove the skeleton loader
    loader.remove();

    // "stamp" the template
    const template: HTMLTemplate = doc.querySelector('template');
    const node = template.content.cloneNode(true);
    shadow.append(node);

    // set the section content
    shadow.querySelector(
      'section'
    ).innerHTML = `${titleResult}${introductionResult}${instructionListResult}${additionalInfoListResult}`;

    XGadgetGuideResponse.updateVideoModalBrowserType(doc, browserType);
  }

  // set the contents of the skeleton loader and append it to the parent element
  public static renderSkeletonLoader(
    loader: HTMLDivElement,
    shadow: ShadowRoot,
    contents: string
  ) {
    loader.innerHTML = contents;

    shadow.host.parentElement.append(loader);
  }

  // look in state and determine if we should render. we're setting a hard limit per session, including refreshes (f5)
  // via "session storage" as on a 403 of the endpoint, squirrelly would re-render the component infinitely
  public static shouldRenderGadgetGuide() {
    const renderCount = state.gadgetGuideRenderCount;
    const maxRenderCount = 5;

    if (renderCount < maxRenderCount) {
      state.gadgetGuideRenderCount = renderCount + 1;
    }

    return renderCount < maxRenderCount;
  }

  // remove the loader and show the default content
  public static showDefaultContent(doc: Document, loader: HTMLDivElement) {
    loader.remove();

    doc
      .querySelector(`div[${GadgetGuide.DefaultContentAttribute}]`)
      ?.removeAttribute(`${GadgetGuide.DefaultContentAttribute}--hidden`);
  }

  // update the browser type in the video modal
  public static updateVideoModalBrowserType(
    doc: Document,
    browserType: string
  ) {
    const videoModal = doc.getElementById('video-check-modal-browser-type');

    if (videoModal) {
      videoModal.textContent = browserType;
    }
  }
}

export function registerGadgetGuideResponse() {
  if (state.isVideoCheckModalV2Enabled) {
    if (!customElements.get(`x-${GadgetGuide.ComponentName}`)) {
      customElements.define(
        `x-${GadgetGuide.ComponentName}`,
        XGadgetGuideResponse
      );
    }

    Sqrl.templates.define(
      GadgetGuide.ComponentName,
      Sqrl.compile(template, { useWith: true })
    );
  }
}
