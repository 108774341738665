import { UAParser } from 'ua-parser-js';
import { CaptureType, DocumentType } from './types';
import { getLocationSearch } from './window';

export function distinct<T>(a: Array<T>): Array<T> {
  return a.filter((x, i, a) => a.indexOf(x) === i);
}

type CaptureTypeKey = keyof typeof CaptureType;
export function getCaptureTypeFromValue(
  value: CaptureType
): string | undefined {
  return Object.keys(CaptureType).find(
    (x) => CaptureType[x as CaptureTypeKey] === value
  );
}

export function asyncTimeout(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function getDocumentDisplayName(documentType: DocumentType): string {
  if (documentType === DocumentType.Passport) {
    return 'Passport';
  } else if (documentType === DocumentType.DriversLicense) {
    return "Driver's License";
  } else {
    return 'State ID';
  }
}

export function scrollToTop(): void {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

export function removeStickyParent(): void {
  document.body.classList.remove('sticky__parent');
}

export function parseQueryParameters(): Record<string, string> {
  const queryParams: Record<string, string> = {};
  const search = getLocationSearch().split('?');
  if (search.length <= 1) {
    return queryParams;
  }

  const query = search[1];
  const queryParts = query.split('&');

  queryParts.forEach((query) => {
    const paramParts = query.split('=');
    if (paramParts.length > 1) {
      const queryParamKey = paramParts[0];
      const queryParamValue = paramParts.slice(1).join('=');
      queryParams[queryParamKey] = queryParamValue;
    }
  });
  return queryParams;
}

export function getRedirectUriQueryParameter() {
  const redirectUriKey = 'redirectUri';
  const queryParameters = getCapturedQueryParameters();

  if (queryParameters && queryParameters[redirectUriKey]) {
    return decodeURIComponent(queryParameters[redirectUriKey]);
  }

  return null;
}

function getCapturedQueryParameters() {
  return JSON.parse(sessionStorage.getItem('query-parameters'));
}

export function getUserAgent() {
  const uaParser = new UAParser();

  return {
    getBrowser: () => {
      return {
        name: uaParser.getBrowser().name.toLowerCase().includes('safari') ? 'Safari' : uaParser.getBrowser().name,
        version: uaParser.getBrowser().version
      }
    },
    getDevice: () => {
      const getDeviceType = () => {
        return uaParser.getDevice().type === 'mobile' ? 'Mobile' : 'Desktop';
      }

      return {
        model: uaParser.getDevice().model,
        type: getDeviceType(),
        vendor: uaParser.getDevice().vendor,
      }
    },
    getOS: () => {
      return {
        name: uaParser.getOS().name,
        version: uaParser.getOS().version,
      }
    }
  }
}

export function buildGadgetRequestPayload() {
  const uaParser = getUserAgent();

  return {
    browserType: uaParser.getBrowser().name,
    browserVersion: uaParser.getBrowser().version,
    deviceModel: uaParser.getDevice().model,
    deviceName: uaParser.getDevice().type,
    osType: uaParser.getOS().name,
    osVersion: uaParser.getOS().version,
    gadgetType: 'Camera',
  }
}
