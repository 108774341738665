import axios = require('axios');

import { environment } from '../../environments/environment';
import appInsightsService, { MiSnapResultsService } from '../../services';
import msalService from '../msal/msal-auth.service';

import { GadgetGuideResponse } from '../../shared/types';
import { getUserAgent } from '../../shared/utils';
import { ClientTheme, PhotoSubmitRequest, SdkLicense, SessionDetails } from './models';

export class SessionService {
  static postSessionStarted() {
    SessionService.post(`sessions/${msalService.sessionId}/started`, {}).catch(
      appInsightsService.handleGenericNetworkError()
    );
  }

  static postPhotoSubmit(): Promise<unknown> {
    const uaParser = getUserAgent();

    const evidence = MiSnapResultsService.getInstance().getEvidence();
    const request: PhotoSubmitRequest = {
      sessionId: msalService.sessionId,
      evidenceImages: evidence,
      browser: uaParser.getBrowser(),
      device: uaParser.getDevice(),
      os: uaParser.getOS(),
    };
    return SessionService.post('photos/submit', request);
  }

  static postGadgetGuide(gadgetRequestPayload: unknown): Promise<{ data: GadgetGuideResponse }> {
    return SessionService.post('GadgetGuide', gadgetRequestPayload);
  }

  static getSessionDetails(): Promise<SessionDetails> {
    return SessionService.get<SessionDetails>(`sessions/${msalService.sessionId}`).then((resp) => {
      return resp.data;
    }).catch(() => {
      appInsightsService.handleGenericNetworkError();

      return {
        firstName: undefined,
        clientTheme: undefined
      }
    });
  }

  static async getSDKLicense(): Promise<string> {
    const resp = await SessionService.get<SdkLicense>('license');
    return resp.data.license;
  }

  static setFavicon(clientTheme: ClientTheme, iconLinkElement: HTMLElement) {
    iconLinkElement.setAttribute('href', clientTheme.faviconUri);
  }

  static setDocumentTitle(clientTheme: ClientTheme, doc: Document) {
    doc.title = clientTheme.documentTitleText;
  }

  static injectCssVariablesInHead(clientTheme: ClientTheme, doc: Document): void {
    const styles = doc.createElement('style');
    styles.textContent = `
      :root {
        --client-theme-primary-color: ${clientTheme.primaryColorHexCode};
        --client-theme-primary-color-hover-background: ${clientTheme.primaryColorHoverBgHexCode};
        --client-theme-primary-color-hover-text: ${clientTheme.primaryColorHoverTextHexCode};
        --client-theme-secondary-color: ${clientTheme.secondaryColorHexCode};
        --client-theme-secondary-color-hover-background: ${clientTheme.secondaryColorHoverBgHexCode};
        --client-theme-secondary-color-hover-text: ${clientTheme.secondaryColorHoverTextHexCode};
        --client-theme-footer-logo-large-uri: url('${SessionService.matchClientThemeUri(clientTheme.logoLargeUri)}');
        --client-theme-footer-logo-small-uri: url('${SessionService.matchClientThemeUri(clientTheme.logoSmallUri)}');
      }
    `

    doc.querySelector('head').append(styles);
  }

  static matchClientThemeUri(url: string) {
    if (url?.match(/^https:\/\//)) {
      return url;
    }

    return `assets/${url}`;
  }

  private static async post<Treq, Tresp>(path: string, data: Treq): Promise<Tresp> {
    const url = `${environment.apiBaseUrl}${path}`;
    const accessToken = await msalService.acquireToken();

    return axios.default.post(url, data, {
      headers: this.getRequestHeaders(accessToken)
    });
  }

  private static async get<Tresp>(path: string): Promise<axios.AxiosResponse<Tresp>> {
    const url = `${environment.apiBaseUrl}${path}`;
    const accessToken = await msalService.acquireToken();

    return axios.default.get(url, {
      headers: this.getRequestHeaders(accessToken)
    });
  }

  private static getRequestHeaders(accessToken: string | void): axios.RawAxiosRequestHeaders {
    return {
      'auth-settings': 'user-bearer',
      Authorization: `Bearer ${accessToken}`,
      'session-id': msalService.sessionId
    }
  }
}
