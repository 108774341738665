import { environment } from '../../environments/environment';
import axios, { AxiosResponse } from 'axios';
import { FeatureFlag } from './models/feature-flag';

export class FeatureManagementService {
    static isFeatureEnabled(featureName: string): Promise<boolean> {
        return FeatureManagementService.get<FeatureFlag>(`featuremanagement/${featureName}`)
        .then((response) => response.data.isEnabled)
        .catch(() => false);
    }

    private static get<Tresp>(path: string): Promise<AxiosResponse<Tresp>> {
        const url = new URL(path, environment.apiBaseUrl).href;

        return axios.get(url);
    }
}