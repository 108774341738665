import {
  Browser,
  DeviceModel,
  EvidenceImage,
  OS
} from '.';

export interface PhotoSubmitRequest {
  sessionId: string;
  evidenceImages: EvidenceImage[];
  browser: Browser;
  device: DeviceModel;
  os: OS;
}
