// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home-button {
  width: 100%;
  max-width: 350px;
}`, "",{"version":3,"sources":["webpack://./src/pages/error-pages/not-found/not-found.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AADF;;AAIA;EACE,WAAA;EACA,gBAAA;AADF","sourcesContent":["@import '../../../scss/variables';\n\n.button-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n#home-button {\n  width: 100%;\n  max-width: 350px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
