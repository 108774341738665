import { registerSharedComponent } from '../shared-components';
import state from '../../state';

import template from './video-check-modal.html';
import v2template from './video-check-modal-v2.html';
import './video-check-modal.scss';

export function registerVideoCheckModal() {
	if (state.isVideoCheckModalV2Enabled) {
		registerSharedComponent('video-check-modal', v2template);
	} else {
		registerSharedComponent('video-check-modal', template);
	}
}