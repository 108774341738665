import * as Sqrl from 'squirrelly';
import { registerButton } from './button';
import { registerIdChoiceButton } from './id-choice-button';
import { registerLoadingSpinner } from './loading-spinner';
import { registerSimpleErrorPage } from './simple-error-page';
import { registerSupportPhoneNumber } from './support-phone-number';

export function registerComponents() {
  registerButton();
  registerIdChoiceButton();
  registerLoadingSpinner();
  registerSimpleErrorPage();
  registerSupportPhoneNumber();
}

export function registerSharedComponent(name: string, template: string) {
  Sqrl.templates.define(name, Sqrl.compile(template, { useWith: true }));

  Sqrl.filters.define('classes', function (classes: string[], prefix) {
    return classes.map((c) => prefix + c).join(' ');
  });
}
